.msgs-toolbar-button {
    color: #007aff;
    font-size: 28px;
    transition: all 0.1s;
}

.msgs-toolbar-button:hover:not(.disabled) {
    cursor: pointer;
    color: #0063ce;
}

.msgs-toolbar-button:active:not(.disabled) {
    color: #007aff;
    opacity: 0.25;
}
