.msgs-toolbar {
    display: flex;
    align-items: center;

    background-color: white;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;

    position: sticky;
    top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
    .msgs-toolbar {
        border: none;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px);
    }
}

.msgs-toolbar-title {
    margin: 0;
    font-size: 16px;
    font-weight: 800;
}

.msgs-left-items, .msgs-right-items {
    flex: 1;
    padding: 10px;
    display: flex;
}

.msgs-right-items {
    flex-direction: row-reverse;
}

.msgs-left-items .msgs-toolbar-button {
    margin-right: 20px;
}

.msgs-right-items .msgs-toolbar-button {
    margin-left: 20px;
}

.msgs-left-items .msgs-toolbar-button:last-child,
.msgs-right-items .msgs-toolbar-button:last-child {
    margin: 0;
}
