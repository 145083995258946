.conversation-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    color: inherit;
}

.conversation-list-item:hover {
    background: #eeeef1;
    cursor: pointer;
}

.conversation-list-item.selected {
    background: #c2e0ff !important;
}

.conversation-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.conversation-title {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
}

.conversation-checkmark {
    margin-left: 15px;
}

.conversation-info {
    width: calc(100% - 60px);
}

.conversation-snippet {
    font-size: 14px;
    color: #888;
    margin: 0;
    float: left;
}

.conversation-unread:not(.mine) {
    float: right;
    background-color: red;
    color: #fff;
    border-radius: 20px;
    min-width: 25px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
}

.conversation-unread.mention:not(.mine) {
    background-color: #007aff;
    margin-left: 8px;
}

.conversation-unread.mine {
    float: right;
    color: #007aff;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding-right: 1px;
}

.conversation-unread-donate {
    float: right;
    color: #007aff;
    font-size: 14px;
    text-align: center;
    padding-right: 3px;
}
