.NotifiCounter {
  display: inline-block;
  background-color: #fa3e3e;
  color: $white;
  border-radius: 2px;
  font-size: 10px;
  padding: 2px;
  min-width: 14px;
  text-align: center;
  line-height: 12px;
  height: 16px;
  margin-left: 0.2rem;
}

.TopRightMenu__notificounter {
    position: absolute;
    top: -2px;
    right: 0;
    > .NotifiCounter {
        display: block;
    }
}
