.VerticalMenu {

  width: 200px;

  .Icon {
    padding-left: 0.1rem;
    margin-right: 1.3rem;
    top: 0;
    fill: $dark-gray;
  }

  > li > a {
    display: flex;
    align-items: center !important;
    line-height: 1rem;
    position: relative;
    padding: 0.7rem 1rem;

    @include themify($themes) {
      color: themed('textColorPrimary');
    }

    font-size: 14px;
    letter-spacing: 0.4px;
  }

  > li > a:hover {
    background-color: #f0f0f0;
  }

  > li.title {
    padding: 0.56rem;
    @include themify($themes) {
      color: themed('textColorPrimary');
    }
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 16px;
    text-align: center;
    border-bottom: 1px solid $light-gray;
  }

  > li.description {
    padding: 0.1rem;
    font-size: 87.5%;
    text-align: center;
    border-bottom: 1px solid $light-gray;
  }

  &_nav-profile {
    width: 262px;

    & > li > a {
      padding: 0 1rem 0 3.8rem;
      line-height: 50px;

      @media screen and (max-width: 39.9375em) {
        padding: 0 20px;
      }

      .Icon {
        @include themify($themes) {
          fill: themed('textColorPrimary');
        }
      }

      &:hover {
        .Icon {
          fill: #3F46AD;
        }
      }
    }

  }

  &_nav-additional {
    width: 260px;
    margin: 10px 0;

    & > li > a {
      padding: 0 20px;
      line-height: 50px;
    }
  }

}
