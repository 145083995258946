.MessagesBox {
    padding: .25rem 0;
    .close-button {
      right: 0.25em;
      top: 0.5em;
    }
    .row {
      .column {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  
