.progress-container {
  cursor: pointer;
}

.progress-bar {
  transform: rotate(-90deg) scaleX(-1);
}

.progress-bar circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1.5s ease-in-out;
  stroke: #666;
}

.progress-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: none;
  line-height: 1;
}
.progress-text p {
  font-size: 0.2em;
  margin-bottom: 0;
}
.progress-text .percentage {
  font-size: 0.4em;
}
