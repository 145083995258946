.tooltip {
  margin-top: -1rem;
}

.dropdown-pane {
  width: auto;
  padding: 0;
  z-index: 1000;
  font-size: inherit;
  border: none;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.15), 0 2px 12px 0 rgba(0,0,0,0.15);
  &.fade {
    opacity: 0;
    transition: opacity 100ms linear;
  }
  &.in {
    opacity: 1;
  }
}

div[role=dialog] {
  z-index: 500;
}

input[type=button]:disabled,
input[type=button].disabled, input[type=button].disabled:focus,
input[type=submit].disabled, input[type=submit].disabled:focus {
  opacity: 0.25;
  cursor: inherit;
  background-color: $mainly-blue;
}

button, .button {
  text-transform: uppercase;
}

.button {
  text-align: center;
  font-weight: bold;
}
