.LoginForm {
  .logo {
    display: none;
  }
  @media screen and (max-width: 39.9375em) {
    .logo {
      display: block;
    }
  }
  label {
    text-transform: none;
  }
  form {
    max-width: 440px;
  }
  .button.sign-up {
      background-color: #4078C0 !important;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.2rem;
      text-transform: none;
  }
  .free-slogan {
      font-weight: bold;
  }
  .free-money {
      font-weight: bold;
  }
  hr {
      margin: 1.75rem auto 2rem auto;
  }
  p {
      margin-bottom: 2rem;
  }
}
