.MyGroups {
    h3 {
        margin-bottom: 0.75rem;
    }
    .group-logo {
        width: 67px;
        img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }
    }
    .group-title {
        font-weight: bold;
        font-size: 110%;
        @include themify($themes) {
            color: themed('textColorPrimary');
        }
    }
    .create-group {
        margin-right: 0.5rem !important;
    }
    .more-group {
        float: right;
        padding-top: 0.725em;
        padding-bottom: 0.725em;
        &:not(:hover) {
            border-color: transparent;
        }
    }
    .group-buttons {
        float: right;
        padding-top: 0.8rem;
        cursor: default;
        .button {
            vertical-align: middle;
            margin-bottom: 0px;
        }
        .DropdownMenu.show > .VerticalMenu {
            transform: translateX(-100%);
        }
    }
    .btn-title {
        margin-left: 5px;
        vertical-align: middle;
    }
    .button.icon-only {
        .btn-title {
            display: none;
        }
    }
    .button.force-white {
        color: #fefefe !important;
        fill: #fefefe !important;
    }
    .label {
        padding: 0.5rem;
        padding-top: 0.6rem;
        padding-bottom: 0.4rem;

        transition: all .1s ease-in;
        user-select: none;

        margin-right: 0.5rem;
        margin-bottom: 0.5rem;

        .label-text {
            display: inline-block;
            height: 16px;
        }
        .NotifiCounter {
            margin-left: 0.35rem;
            vertical-align: top;
        }

        &:not(.disabled) { 
            cursor: pointer;
        }
        &:not(.checked) {
            background: #f4f4f8;
            color: #333333;
        }
        &:hover:not(.disabled) {
            background: #0078C4;
            color: #fefefe;
        }
    }
}
