.conversation-search {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;

    background-color: white;
    border-bottom: 1px solid #eeeef1;
}

@supports (backdrop-filter: blur(20px)) {
    .conversation-search {
        border: none;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px);
    }
}

.conversation-search-input {
    background: #f4f4f8 !important;
    padding: 8px 10px !important;
    border-radius: 10px !important;
    border: none !important;
    font-size: 14px !important;
    height: auto !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.conversation-search-input::placeholder {
    text-align: center;
    opacity: 1 !important;
    color: #888 !important;
}

.conversation-search-input:focus::placeholder {
    text-align: left;
}

.conversation-search-buttons {
    position: absolute;
    top: 13px;
    right: 20px;
    color: #bbbbbf;
    cursor: pointer;
    padding: 4px;
}

.conversation-search-buttons:hover {
    color: #99999c;
}
