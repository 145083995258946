.Userpic {
  display: inline-block;
  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 50%;

  width: 48px;
  height: 48px;

  overflow: hidden;

  .progressbar {
    position: relative;
  }

  .progress-text {
    opacity: 0;
  }

  .voting_power {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 2;
    transition: opacity .3s ease-in-out;

    &.show-progress {
      opacity: 1;
    }

    &.show-power {
      background-color: rgba(74, 81, 182, 0.8);

      .progress-text {
        opacity: 1;
      }
    }
  }

}

.Userpic_parent {
  position: relative;
}

.Userpic__badge {
  position: absolute;
  z-index: 10;
  bottom: 3px;
  right: -2px;
  background-color: white;
  font-size: 0.65rem;
  border-radius: 50%;
  border: 1px solid #dadada;
  color: $dark-gray;
  padding-left: 3px;
  padding-right: 3px;
}
