.GroupSettings {
    h3 {
        padding-left: 0.75rem;
    }
    .input-group-label.button {
        margin-bottom: 0px;
    }
    .input-group {
        margin-bottom: 0px;
    }
    .error {
        margin-bottom: 0px;
        margin-top: 5px;
    }
    .submit-error {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
