
.Input {
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;

  &_block {
    display: block;
    width: 100%;
  }
}
