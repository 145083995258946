
.Dialog {
  $border-radius: 8px;

  position: relative;
  min-width: 364px;
  max-width: 100%;
  color: #333;
  background: #fff;
  border-radius: $border-radius;
  box-shadow: 0 0 19px 3px rgba(0,0,0, 0.2);

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 30px;
    height: 30px;
    padding-top: 7px;
    text-align: center;
    color: #e1e1e1;
    cursor: pointer;
    transition: color 0.1s;

    &:hover {
      color: #000;
    }

    & > svg {
      width: 14px;
      height: 14px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    height: 70px;
    font-size: 18px;
    font-weight: 500;
  }

  &__content {
    padding: 10px 30px 28px;
    line-height: 1.5em;
    color: #666;
  }

  &__footer {
    display: flex;
    height: 50px;
    border-radius: 0 0 $border-radius $border-radius;
    box-shadow: 0 -2px 14px 0 rgba(0,0,0, 0.07);
    overflow: hidden;
  }

  &__footer-button-wrapper {
    position: relative;
    margin-right: -1px;
    flex-basis: 70px;
    flex-grow: 1;

    &::after {
      position: absolute;
      top: 7px;
      right: 0;
      bottom: 7px;
      content: '';
      width: 1px;
      background: #f3f3f3;
    }

    &:last-child {
      margin-right: 0;

      &::after {
        content: none;
      }
    }
  }

  &__footer-button {
    display: block;
    width: 100%;
    border-radius: 0;
    height: 50px;
    font-size: 15px;
    font-weight: 500;
    margin: 0 !important;
    cursor: pointer;
    outline: none;
    color: #6d6d6d;

    &:focus {
      background: #ddebff;
    }

    &_primary {
      color: #2879ff;
    }

    &_warning {
      color: #ff4641;
    }

    &:hover {
      color: #3a3a3a;
    }

    &_primary:hover {
      color: #60a0ff;
    }

    &_warning:hover {
      color: red;
    }

    &_warning:focus {
      background: #ffe3dd;
    }
  }
}
