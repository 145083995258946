.CreateGroup {
    h3 {
        padding-left: 0.75rem;
    }
    .next-button {
        width: 48px;
        height: 48px;
        padding-left: 13px !important;
        color: white;
        position: absolute;
        bottom: 0.4rem;
        right: 0.5rem;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
    }
    .Stepper {
        width: 85%;
        margin-left: 1rem;
    }
    .icon-hint {
        margin-left: 0.25rem;
    }
    .column {
        padding-right: 0.5rem !important;
    }
    .error {
        margin-bottom: 0px !important;
        margin-top: 0.5rem;
    }
    .submit-error {
        margin-left: 1rem;
        max-width: 83%;
    }

    .image-loader {
        margin-top: 14px;
    }

    .image-preview {
        max-width: 75px;
        max-height: 75px;
        margin-top: 0.75rem;
        border: none;
    }
    .submit-loader {
        margin-left: 0.5rem;
        margin-top: 0.5rem;
    }
}
