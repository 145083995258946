@import "./foundation-settings";
@import "~foundation-sites/scss/foundation";

@include foundation-everything(true);

@import "./variables";
@import "./mixins";
@import "./layout";
@import "./themes";

@import "./foundation-overrides";
@import "./fonts";
@import "./forms";

body {
    fill: currentColor;
}

@import "app/components/all";

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.secondary {
  color: $dark-gray;
  font-size: 90%;
  a {
    color: $dark-gray;
    text-decoration: underline;
  }
}
