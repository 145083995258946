.LoginDialog {
  min-width: 700px;

  @media screen and (max-width: 700px) {
    min-width: 200px;
    width: 100%;
  }

  .button {
    margin-bottom: 0px;
  }
}
