.msgs-message {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.msgs-message .timestamp {
    display: flex;
    justify-content: center;
    color: #999;
    font-weight: 600;
    font-size: 12px;
    margin: 10px 0px;
    text-transform: uppercase;
}

.msgs-message .bubble-container {
    font-size: 14px;
    display: flex;
}

.msgs-message .bubble-container .author {
    font-size: 98%;
    font-weight: bold;
    color: #0078C4;
    padding-bottom: 3px;
}
.msgs-message .bubble-container .author.banned {
    text-decoration: line-through;
    color: #999;
}
.msgs-message .bubble-container .avatar {
    width: 42px;
    margin-top: 14px;
    cursor: pointer;
}
.msgs-message .bubble-container .avatar .Userpic {
    position: static;
}

.msgs-message .bubble-container a {
    color: #007aff;
    text-decoration: underline;
    font-weight: bold;
}
.msgs-message .bubble-container a.mention {
    text-decoration: none;
}

.msgs-message.mine .bubble-container {
    justify-content: flex-end;
}

.msgs-message.mine .bubble-container a {
    color: #ffcccc;
    justify-content: flex-end;
}

.msgs-message.start .bubble-container .bubble {
    margin-top: 10px;
    border-top-left-radius: 20px;
}

.msgs-message.end .bubble-container .bubble {
    border-bottom-left-radius: 20px;
    margin-bottom: 10px;
}

.msgs-message.mine.start .bubble-container .bubble {
    margin-top: 10px;
    border-top-right-radius: 20px;
}

.msgs-message.mine.end .bubble-container .bubble {
    border-bottom-right-radius: 20px;
    margin-bottom: 10px;
}

.msgs-message .bubble-container .bubble {
    margin: 1px 0px;
    background: #f4f4f8;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 75%;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor: pointer;
}

.msgs-message .bubble-container.selected .bubble {
    background: #fc544e !important;
    color: white;
}

.msgs-message.highlight .bubble-container .bubble {
    background: #fc544e !important;
    color: white;
}

.msgs-message.mine .bubble-container .bubble {
    background: #007aff;
    color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.msgs-message.mine .bubble-container .bubble.loading {
    background: #c2e0ff;
}

.msgs-message.mine .bubble-container .bubble.loading a {
    color: #007aff;
}

.msgs-message.mine.start .bubble-container .unread {
    margin-top: 10px;
}

.msgs-message .bubble-container .unread {
    padding: 10px 15px;
    color: #007aff;
    font-size: 12px;
}

.msgs-message .bubble-container .unread.loading {
    color: #c2e0ff !important;
}

.msgs-message .bubble-container.selected .unread {
    color: #fc544e !important;
}

.msgs-message .quote {
    margin-left: 0.1rem;
    margin-bottom: 0.2rem;
    padding-left: 0.5rem;
    flex: 1;
    border-left: #007aff solid 2px;
    font-size: 14px;
}

.msgs-message .quote-from {
    font-weight: bold;
    color: #007aff;
}

.msgs-message.mine .quote {
    border-left: #70fff2 solid 2px;
}

.msgs-message.mine .quote-from {
    color: #70fff2;
}

.msgs-message .quote img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 60px;
    height: 30px;
}

.msgs-message.mine .bubble.loading .quote {
    border-left: #007aff solid 2px;
}

.msgs-message.mine .bubble.loading .quote-from {
    color: #007aff;
}

.msgs-message.mine .bubble-container.selected .quote {
    border-left: white solid 2px;
}

.msgs-message.mine .bubble-container.selected .quote-from {
    color: white;
}

.msgs-adds {
    display: flex;
}

@media screen and (max-width: 39.9375em) {
    .msgs-adds {
        display: block;

        .msgs-donating {
            margin-left: 3px;
        }
    }
}
