$color-white: #fff;
$color-black: #000;
$color-red: #fc544e;
$color-blue: #004EFF;
$color-blue-original-dark: #1A5099;
$color-blue-original-light: #4BA2F2;
$color-blue-black: #171F24;
$color-blue-black-darkest: #11161A;
$color-blue-dark: #2C3A45;
$color-teal: #3F46AD;
$color-teal-light:#00FFC8;
$color-teal-dark:#049173;

$color-yellow: #fce76c;
$color-orange: #F76900;
$color-transparent: transparent;

$color-background-almost-white:#fefefe;
$color-background-off-white: #fcfcfc;
$color-background-dark: #1C252B;
$color-background-super-dark: #10151b;

$color-text-dark: #333;
$color-text-white: #fcfcfc;
$color-text-blue: #004EFF;
$color-text-gray: #788187;
$color-text-gray-light: #A6B2BA;
$color-text-teal: #1FBF8F;
$color-text-red: $color-red;

$color-border-light: #eee;
$color-border-light-lightest: #f6f6f6;
$color-border-dark: #232F38;
$color-border-dark-lightest: #2B3A45;

$font-primary: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;

$alert-color: $color-red;
