form {
  .error {
    input, input:active, input:focus {
      border-color: $alert-color;
    }
    label {
      color: $alert-color;
    }
  }
  .success {
    color: $success-color;
  }
}
button {
    margin-right: 15px !important;
}
input, textarea, select {
    // Overwrite 16px margin-bottom, it was pusing error messages down away from the form element
    margin-bottom: 0 !important;
}
.error {
    color: $alert-color;
    margin-bottom: 10px;
}
.warning {
    color: darkgoldenrod;
    margin-bottom: 10px;
}
.darkred {
    color: darkred;
    margin-bottom: 10px;
}
.info {
    color: dimgrey;
    margin-bottom: 10px;
}
.hoverBackground:hover {
    background-color: antiquewhite;
}
.de-emphasize {
  color: $dark-gray;
}
.overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}
.darkbtn {
    padding: 12px;
    background-color: #eee;
}

// // what about touch-screen only?
// .hoverShow {
//     visibility: hidden;
// }
// // what about touch-screen only?
// .hoverShowTrigger:hover > .hoverShow {
//     visibility: visible;
// }
