.message-list-container {
    padding: 10px;
}

.msgs-emoji-picker-tooltip {
    display: block !important;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    left: 0px;
}

.msgs-emoji-picker-tooltip:not(.shown) {
    display: none !important;
}

.msgs-emoji-picker-tooltip emoji-picker {
    height: 290px !important;
}

.Icon.msgs-icon {
    vertical-align: baseline;
}

.Icon.msgs-icon svg {
    vertical-align: baseline;
}
