.msgs-toolbar {
    height: 61px !important;
}
.conversation-list {
    .msgs-toolbar {
        top: -61px !important;
    }
}

.msgs-back-btn {
    display: none;
}

@media screen and (max-width: 39.9375em) {
    .msgs-back-btn {
        display: inline-block;
    }
}

.msgs-curruser {
    cursor: pointer;
    padding-top: 6px;
    user-select: none;
}

.msgs-curruser-notify-sink {
    display: inline-block;

    position: relative;
}

.msgs-curruser-name {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
    margin-left: 10px;
}

.msgs-checkmark  {
    margin-left: 3px;
    svg {
        pointer-events: none;
        color: green;
        fill: green;
        stroke: green;
    }
}
