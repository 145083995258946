.DropdownMenu {
  position: relative;
  display: inline-block;

  .Icon.dropdown-arrow {
    top: 2px;
    margin-right: 0;
  }

  > .VerticalMenu {
    visibility: hidden;
    // min-width: 145px;
    min-width: 232px;
    z-index: 1000;

    @include themify($themes) {
      background-color: themed('summaryBackgroundColor');
    }

    display: block;
    border: 1px solid $medium-gray;
    border-radius: $global-radius;
    opacity: 0;
    position: absolute;
    top: 100%;

    // width: auto;
    transform: translateY(10%);
    transition: all 0.3s ease 0s, visibility 0s linear 0.3s;
    box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.75);
  }

  &.show > .VerticalMenu {
    visibility: visible;
    opacity: 1;
    transform: translateX(0%);
    transition-delay: 0s;
  }

  .DropdownMenu.move-left {
    .VerticalMenu {
      left: -50%;
    }
  }

  &.above > .VerticalMenu {
    bottom: 100%;
    top: auto;
  }

  &.top-most > .VerticalMenu {
    position: fixed;
    top: auto;
  }
}
