.msgs-compose {
    padding: 10px;
    display: flex;
    align-items: center;
    background: white;
    border-top: 1px solid #eeeef1;
    position: fixed;
    width: calc(100% - 368px);
    bottom: 0px;
}

@supports (backdrop-filter: blur(20px)) {
    .msgs-compose {
        border: none;
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px);
    }
}

@media screen and (max-width: 39.9375em) {
    .msgs-compose {
        width: 100%;
    }
}

.msgs-compose-input-panel {
    flex: 1;
}

.msgs-compose-send {
    margin: 0px !important;
    width: 38px;
    height: 38px;
    padding: 0px !important;
    color: white;
}

.msgs-compose-reply {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    flex: 1;
    border-left: #007aff solid 2px;
    font-size: 14px;
}

.msgs-compose-reply-from {
    font-weight: bold;
    color: #007aff;
}

.msgs-compose-reply-close {
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    cursor: pointer;
}

.msgs-compose-reply-close:hover {
    color: #007aff;
}

.msgs-compose-input {
    border: none !important;
    font-size: 14px;
    height: 40px;
    background: none !important;
    box-shadow: none !important;
    -webkite-box-shadow: none !important;
    resize: none;
}

.msgs-compose-input::placeholder {
    opacity: 1 !important;
    color: #888 !important;
}

.msgs-compose .msgs-toolbar-button {
    color: #bbbbbf;
    margin-right: 12px;
}

.msgs-compose .msgs-toolbar-button:hover {
    color: #99999c;
}

.msgs-compose-panel {
    width: 100%;
    height: 50px;
    padding: 8px;
}

.msgs-compose-panel button .Icon {
    display: none;
}

@media screen and (max-width: 39.9375em) {
    .msgs-compose-panel button .Icon {
        display: inline-block;
    }

    .msgs-compose-panel button span {
        display: none;
    }

    .msgs-compose-panel .cancel-button {
        border: none;
    }
}

.msgs-compose-panel .edit-button {
    float: right;
}

.msgs-compose-panel .delete-button {
    float: right;
}

.msgs-compose-panel .cancel-button {
    float: right;
}
