.messenger-dropzone {
    width: auto;
    height: auto;
    border: none;
    position: static;
}

.messenger-dropzone-shade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 2;
    -webkit-animation: fade-in 0.2s;
    animation: fade-in 0.2s;
}

.messenger-dropzone-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px dashed #fff;
    margin: 20px;
    border-radius: 20px;
}

.messenger {
    display: grid;
    width: 100%;
    height: 100vh;
    background: #eeeef1;

    grid-template-columns: 350px auto;
    grid-template-rows: 60px auto 60px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
}

@media screen and (max-width: 39.9375em) {
    .messenger {
        grid-template-columns: 100%;
    }
}

.msgs-container {
    padding: 10px;
}

.msgs-scrollable {
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.msgs-sidebar {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
}

.msgs-content {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
}

.msgs-footer {
    grid-column-start: 2;
    background: white;
}

.msgs-empty-chat {
    opacity: 40%;
    width: 70%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
}

.msgs-start-panel {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #bbb;
}
